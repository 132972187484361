module.exports = (function ($, I18n) {

  var s = {
    locale: 'en',
    warningPercentage: 50,
    warningClass: 'timer-warning',
    imminentPercentage: 25,
    imminentClass: 'timer-imminent',
    selector: '.session-timer'
  };

  function init(opts){
    $.subscribe('/updater/add/sessionTimer', add);
  }

  function getTime() {
    return new Date().getTime() / 1000;
  }

  function isOk(percentage) {
    return percentage > s.warningPercentage;
  }

  function notifyOk(element) {
    element
      .removeClass(s.warningClass)
      .removeClass(s.imminentClass);
  }

  function shouldWarn(percentage) {
    return percentage <= s.warningPercentage;
  }

  function notifyWarning(element) {
    element.removeClass(s.imminentClass);
    if (!element.hasClass(s.warningClass)) {
      element.addClass(s.warningClass);
    }
  }

  function isImminent(percentage) {
    return percentage <= s.imminentPercentage;
  }

  function notifyImminent(element) {
    element.removeClass(s.warningClass);
    if (!element.hasClass(s.imminentClass)) {
      element.addClass(s.imminentClass);
    }
  }

  function hasTimedOut(time) {
    return time <= 0;
  }

  function notifyTimeOut(element) {
    element.html(I18n.t('javascript.timer.timed_out'));
  }

  function updateDisplay(element, time) {
    var translation = element.data('translation'),
        timerText = '',
        seconds = ~~(time % 60),
        paddedSeconds = ("0" + seconds).slice(-2),
        minutes = ~~((time - (time % 60)) / 60 % 60),
        hours = ~~((time - (time % 60)) / 60 / 60 % 24),
        days = ~~((time - (time % 60)) / 60 / 60 / 24);

    if (days > 0) {
      timerText += days + 'd:';
    }

    if (hours > 0) {
      timerText += hours + 'h:';
    }

    if (minutes > 0) {
      timerText += minutes + ':';
    }

    timerText += paddedSeconds;

    element.html(I18n.t(translation, {seconds: timerText}));
  }

  function updateStatus(element, percentage) {
    if (isOk(percentage)) {
      notifyOk(element);
    }

    if (shouldWarn(percentage)) {
      notifyWarning(element);
    }

    if (isImminent(percentage)) {
      notifyImminent(element);
    }

    if (hasTimedOut(percentage)) {
      notifyTimeOut(element);
    }
  }

  function start(element) {
    var timeout = parseInt(element.data('timeout')),
      timeToFinish = getTime() + timeout,
      timer = setInterval(update, 1000);

    function update() {
      var time = timeToFinish - getTime(),
        percentageLeft = (time / timeout) * 100;

      updateDisplay(element, time);
      updateStatus(element, percentageLeft);

      if (hasTimedOut(percentageLeft)) {
        stop();
      }
    }

    function stop() {
      clearTimeout(timer);
    }

    // not sure it's worth setting a new timeout
    function reset() {
      stop();
      return start(element)
    }

    update();

    return {
      reset: reset
    };
  }

  function add(context) {
    context.find(s.selector).each(function () {
      var timer = start($(this));

      function reset() {
        timer = timer.reset();
      }

      function listenToTimeoutChange(event) {
        if (event.key == 'refreshTimeout') {
          reset();
        }
      }

      addToLocalStorage(this);
      window.addEventListener('storage', listenToTimeoutChange.bind(this));

      $.subscribe('/updater/submit', compose(reset, addToLocalStorage));
      $.subscribe('/sessionTimer/reset', reset);
    });
  }

  function compose() {
    var args = [].slice.call(arguments);
    return function(param){
      return args.reduceRight(function(fna, fnb){
        return fnb(fna)
      }, param)
    }
  }

  function addToLocalStorage(element) {
    var timeout = parseInt($(element).data('timeout'));
    // storage event isn't fired if the value hasn't changed, add a random value to resolve that
    localStorage.setItem('refreshTimeout', Math.random());
    return element;
  }



  return {
    selector: s.selector,
    init: init
  };

}(jQuery, window.I18n));
