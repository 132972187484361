/**
 * Converter:
 * @description: Defines component which performs conversions
 * between imperial and metric systems. It know how to convert
 * centimeters to feets and inches, kilograms to pounds and vise versa.
 * @example: HTML of the component may look like follows:
 * <div>
    <span class="m-converter" data-converter-type="height">
      <span class="m-converter-metric">
        <input type="text" />
      </span>

      <span class="m-converter-imperial">
        <input type="text" />
        <input type="text" />
      </span>
    </span>
  </div>
 * where input within '.m-converter-metric' span contains metric value
 * inputs within '.m-converter-imperial' span contain imperial values.
 * If user changes value of "metric" input both "imperial" inputs will be updated.
 * If user changes value of one of "imperial" inputs then "metric" input will be updated.
 * 'data-converter-type' attribute of '.m-converter' node contains type of conversions.
 * Possible values are:
 * - height - allows conversion centimeters to feets and inches and vise versa
 * - weight - allows conversion kilograms to pounds and vise versa
 * Dependencies:
 * - jquery.js
 * - jquery.pubsub.js
 */

'use strict';

let selector = '.m-converter';
const TAB_KEY_CODE = 9;
const INPUT_TAG_NAME = 'INPUT';
const CONVERSION_RATES = {
  height: {
    ratio: 2.54,     // Ration between meters and inches
    division: 12     // Ratio between feet and inches
  },
  weight: {
    ratio: 0.453592, // Ratio between kilogram and pound
    division: 14     // Ratio between pounds and stone
  }
};

/**
 * @description: Initializes component
 * Sets component selector to passed opts.selector value or leave default value of '.m-converter'
 * @param {Object} opts May contain custom selector for the component
*/
function init(opts) {
  selector = (opts ? opts.selector : undefined) || selector;
  $.subscribe('/updater/add', add);
  $.subscribe('/updater/add/converter', add);
}

/**
 * Adds subscription on keyup event to all input elements
 * @param {jQuery|HtmlElement} context
 */
function add(context) {
  $(context)
    .find(selector)
    .each((idx, element) => {
      let converter = $(element),
        context = getExecutionContext(converter);

      converter.on('keyup.converter', (e) => {
        // only convert inputs and when we're not just tabbing into them
        if (e.target.tagName === INPUT_TAG_NAME && e.keyCode !== TAB_KEY_CODE) {
          convert.apply(e.target, [e.target, context]);
        }
      }).data('m.converter', context);
    });
}

/**
* @description Builds new execution context
*/
function getExecutionContext(converter) {
  const context = {
    converter: converter,
    type: converter.data('converter-type'),
    metricField: converter.find(`${selector}-metric :input:eq(0)`),
    imperialFields: converter.find(`${selector}-imperial :input`)
  };
  if (!CONVERSION_RATES.hasOwnProperty(context.type)) {
    throw Error(`Unsupported converter type '${context.type}'`);
  }
  context.ratio = CONVERSION_RATES[context.type].ratio;
  context.division = CONVERSION_RATES[context.type].division;
  return context;
}

/**
 * Rounds passed value to two decimal digits
 * @param {Number} value Numeric value to round
 */
function roundTwoDecimals(value) {
  return Math.round(value * 100) / 100;
}

/**
 * @description Perfoms actual conversion of numbers
 * @param {HtmlElement} input Input element on which caused recalculation
 * @param {Object} context Component context information
 */
function convert(input, context) {
  let metricField = context.metricField,
    oneField = (context.imperialFields.length === 1),
    impField1 = context.imperialFields.eq(0),
    impField2 = context.imperialFields.eq(1),
    $input = $(input),

    metricToImperial = function() {
      let totalImpField2 = roundTwoDecimals(metricField.val() / context.ratio),
        totalImpField1 = oneField ? totalImpField2 : Math.floor(totalImpField2 / context.division);
      return [totalImpField1, roundTwoDecimals(totalImpField2 - (totalImpField1 * context.division))];
    },

    imperialToMetric = function() {
      let total = parseFloat(impField1.val()||0);
      if (!oneField) {
        total = (total * context.division) + parseFloat(impField2.val() || 0);
        return Math.round(total * context.ratio);
      } else {
        return roundTwoDecimals(total * context.ratio);
      }
    };

  // stop non numeric and negative values
  this.value = isNaN(this.value) ? '' : this.value.replace(/^-/, '');

  if ($input.parents(`${selector}-metric`).length) {
    let impFields = metricToImperial();
    impField1.val(impFields[0] || '');
    impField2.val(impFields[1] || '');
    if (impFields[0]) {
      impField1.blur();
    }
    if (impFields[1]) {
      impField2.blur();
    }
  } else {
    metricField.val((imperialToMetric() || ''));
    if (imperialToMetric()) {
      metricField.blur();
    }
  }
}

module.exports = {
  selector: selector,
  init: init
};
