/**
 * Zipcode:
 * @desc: Get the location information from a zip code and populate some fields with it
 * @usage: Add the class m-zipcodes to the element that you want to use this module
 * Add m-zipcodes-populate-with-KEY where KEY is the key from the API to have the field populated with the right value
 * Add m-zipcodes-scope on the highest element you want to be the scope of search for the fields to be populated
 * @example: An element with `m-zipcodes-populate-with-state` class will get populated with the state for the
 * given zipcode
 *
  <div class='m-zipcodes-scope'>
    <input type="text" class="m-zipcodes-populate-with-state" name="state" />
    <input type="text" class="m-zipcodes-populate-with-city" name="city" />
    <input type="text" class="m-zipcodes" name="state" />
    <button class="m-zipcodes-search-button">Search</button>
    <select class="m-zipcodes-country-selector">
      <option value='US' >United States of America</option>
      <option value='FR' selected='selected'>France</option>
    </select>
    <div class="m-zipcodes-enabled-countries hide" data-zipcode-country="US"></div>
  </div>
 */

/**
 * @typedef {object} ClickEvent
 * @typedef {object} KeyupEvent
 */

'use strict';

const modal = require('./modal');
const I18n = window.I18n;
const s = {
  locale: I18n.locale,
  selector: '.m-zipcodes',
  submitButtonSelector: '.m-zipcodes-search-button',
  scopeSelector: '.m-zipcodes-scope',
  countrySelector: '.m-zipcodes-country-selector',
  enabledCountrySelector: '.m-zipcodes-enabled-countries',
  endpoint: '/search/zipcodes',
  // @todo: this should be defined in the DOM
  capitalize: {'city': true}
};

let results = {};

/**
 * @description Initialise the module
 * @param {Object} opts - Options to pass on init
 * @param {jQuery} opts.countrySelector - A jQuery object pointing to country selector, used for late init
 */
function init(opts) {
  let $insertAfterElements = $(s.selector);
  $.extend(true, s, opts);

  if (!hasScope($insertAfterElements)) {
    return false;
  }

  listenToCountryChange();
  $insertAfterElements.each((_i, el) => {

    const $button = createSubmitButton();

    $button.insertAfter($(el));

    if (!isSelectedCountryWithZipcode($(el))) {
      $button.hide();
      return;
    }

    addEventListeners({
      $button: $button,
      $input: $(s.selector)
    });
  });
}

/**
 * @description Create a button
 * @return {jQuery} the created button
 */
function createSubmitButton() {
  return $('<button>')
    .text(I18n.t('javascript.search'))
    .addClass(
      'm-button m-button-theme m-button-next m-zipcodes-style_search-button')
    .addClass(s.submitButtonSelector.slice(1))
    .attr('type', 'button');
}

/**
 * @description Add events to search button, text input and modal link
 * @param {Object} elements - The elements to attach event onto
 * @param {jQuery} elements.$button - The search button
 * @param {jQuery} elements.$input - The text input for zipcode
 */
function addEventListeners({$button, $input}) {
  removeEventListeners({$button, $input});

  $button.on('click', displayResult);
  $input.on('keyup', handleKeyup);

  $('body').on('click', '.m-zipcodes_modal-body a', closeModalAndPopulate);
}

/**
 * @description Remove events to search button, text input and modal link
 * @param {Object} elements - The elements to remove event from
 * @param {jQuery} elements.$button - The search button
 * @param {jQuery} elements.$input - The text input for zipcode
 */
function removeEventListeners({$button, $input}) {
  $button.off('click');
  $input.off('keyup');
  $('body').off('click', '.m-zipcodes_modal-body a', closeModalAndPopulate);
}

function handleKeyup(e){
  checkSubmit(e);
  debounce(prefetch, 300)(e);
}
/**
 * @description Either display a modal with the result or display an error message
 * @param {ClickEvent} e
 */
function displayResult(e) {
  const $trigger = $(e.target);
  const $scope = $trigger.closest(s.scopeSelector);
  const $input = $scope.find(s.selector);
  const zipcode = $input.val();

  s.$current = $input;

  if (!zipcode) {
    displayErrorMessage(I18n.t('javascript.zipcode_needed'));
  } else {
    fetch(zipcode)
      .then(response => {
        displayResultInPopup(response); 
      })
      .catch(() => {
        displayErrorMessage(I18n.t('javascript.no_results')); 
      });
  }
}
/**
 * @description Open a modal with a list of result
 * @param {Object[]} results - A list of result
 * @param {String} results[].location_text - A human friendly result text
 *
 */
function displayResultInPopup(results) {
  let $resultsHTML = $();
  $.each(results, function(key, result) {
    const $a = $(`<a href="#">${result.location_text}</a>`).data(result);
    const $li = $('<li>').append($a);

    $resultsHTML = $resultsHTML.add($li);
  });

  const $body = $(`<div class="m-zipcodes_modal-body m-zipcodes-style_modal-body">
        <h3>${I18n.t('javascript.search_results')}</h3>
        <ul class="m-zipcodes-style_result-list"></ul>
        </div>`);

  $body.find('ul').append($resultsHTML);

  modal.open({
    inline: true,
    href: $body,
    width: 400
  });
}

/**
 * @description Open a modal with an Error message
 * @param {String} msg - Error message to display
 */
function displayErrorMessage(msg) {
  const $body = $(`<div class="m-zipcodes_modal-body m-zipcodes-style_modal-body">
        <h3>${msg}</h3>
        </div>`);
  modal.open({
    inline: true,
    href: $body,
    width: 400
  });
}

/**
 * @description request the zipcode information and cache it
 * @param {String} zipcode
 * @returns {Promise} Success only if the result is an non-empty array
 */
function fetch(zipcode) {
  return new Promise((resolve, reject) => {
    if (results[zipcode]) {
      resolve(results[zipcode]);
    } else {
      $.get(s.endpoint, {q: zipcode})
        .done(response => {
          if ($.isArray(response) && response.length) {
            results[zipcode] = response;
            resolve(response);
          } else {
            reject('ZipcodeNoResults');
          }
        })
        .fail(function() {
          reject('ZipcodeLookupError');
        });
    }
  });
}

/**
 * @description Fetch the zipcode result, result gets cached
 * @param {KeyupEvent} e
 */
function prefetch(e) {
  const zipcode = $(e.target).val();
  fetch(zipcode).catch(function() { });
}

/**
 * @description Take an keyup event and call displayResult if the key is ENTER
 * @param {KeyupEvent} e
 */
function checkSubmit(e) {
  const ENTER = 13;
  const keyCode = (window.event) ? e.which : e.keyCode;
  if (keyCode === ENTER) {
    displayResult(e);
  }
}

/**
 * @description Receives a click event close the modal and call populate
 * @param {ClickEvent} e
 * @returns {boolean} False
 */
function closeModalAndPopulate(e) {
  modal.close();
  populate($(e.target).data());
  return false;
}

/**
 * @description check that the given element has a scope
 * @param {jQuery} $el - the element to verify
 * @returns {boolean}
 */
function hasScope($el) {
  return $el.closest(s.scopeSelector).length > 0;
}

/**
 * @description Checks whether the selected country has a zipcode
 * @param {jQuery} $el - a jQuery Element will be used to find the scope
 * @return boolean True if the selected country has zipcode
 */
function isSelectedCountryWithZipcode($el) {
  const $scope = $el.closest(s.scopeSelector);
  const $selectedCountry = $scope.find(s.countrySelector);
  const countriesWithZipcode = countriesWithZipcodes($selectedCountry);
  return !!~countriesWithZipcode.indexOf($selectedCountry.val());
}

/**
 * @description Attach an event to country selector and toggle button on country selection change
 */
function listenToCountryChange() {
  $(document).on('change', s.countrySelector, function() {
    const $scope = $(this).closest(s.scopeSelector);
    const $searchButton = $scope.find(s.submitButtonSelector);
    const $input = $scope.find(s.selector);

    if (isSelectedCountryWithZipcode($(this))) {
      $searchButton.show();
      addEventListeners({
        $button: $searchButton,
        $input: $input
      });
    } else {
      $searchButton.hide();
      removeEventListeners({
        $button: $searchButton,
        $input: $input
      });
    }
  });
}

/**
 * @description find all countries with zipcode enabled
 * @param {jQuery} countrySelector - Element of country selection dropdown
 * @returns {Array} - list of country code which have zipcode enabled
 * @example
 * // returns ['US']
 * countriesWithZipcodes(el)
 */
function countriesWithZipcodes(countrySelector) {
  const enabledCountriesSelectors = $(countrySelector)
      .closest(s.scopeSelector)
      .find(s.enabledCountrySelector),
    enabledCountries = [];

  enabledCountriesSelectors.each(function() {
    enabledCountries.push($(this).data('zipcode-country'));
  });
  return enabledCountries;
}

/**
 * @description populate the fields within the scope that contain `m-zipcodes-populate-with-KEY` class
 * @param {Object} data - An object of data to use for populating the fields
 * @param {JQuery} $current - The zipcode text field that was used
 */
function populate(data, $current = s.$current) {

  // fail silently
  if (!$current) {
    return;
  }

  const selectorWithoutDot = s.selector.slice(1);
  const toPopulate = $current
    .closest(s.scopeSelector)
    .find(`[class*=${  selectorWithoutDot  }-populate-with]`);

  $.each(toPopulate, function(key, input) {
    const classes = $(input).attr('class');
    $.each(classes.split(' '), function(key, className) {
      if (className.indexOf(`${selectorWithoutDot}-populate-with`) !== -1) {
        const populateWith = className.split('-').slice(-1)[0];

        let value;
        if (s.capitalize[populateWith]) {
          value = data[populateWith].replace(/(\S)(\S*)/g,
            function($0, $1, $2) {
              return $1.toUpperCase() + $2.toLowerCase();
            });
        } else {
          value = data[populateWith];
        }
        $(input).val(value);
      }
    });
  });
}

/**
 * @callback debounceCallback
 */

/**
 * @description Calls a callback after x seconds without calling this function
 * @param {debounceCallback} func
 * @param {number} wait
 * @param {boolean} immediate
 */
function debounce(func, wait, immediate) {
  let timeout;
  return function() {
    const context = this, args = arguments;
    const later = function() {
      timeout = null;
      if (!immediate) {
        func.apply(context, args);
      }
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {
      func.apply(context, args);
    }
  };
}

module.exports = {
  selector: s.selector,
  init
};
