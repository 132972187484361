/**
* Assessment Toggler:
* Manipulates 'disabled' attribute on '.m-options' elements
*/

'use strict';

let options = {
    selector: '.m-assessment-options',
    containerSelector: '.m-list-with-assessments-container',
    optionsAreaSelector: '.m-options'
  },
  DISABLED = 'disabled';

/**
* @description Initializes Assessment Toggler component.
* In particular the function subscribes two events:
* - '/updater/add/assessmentToggler'
* - '/updater/beforeAdd/assessmentToggler'
*/
function init() {
  $.subscribe('/updater/add/assessmentToggler', sync);
  $.subscribe('/updater/beforeAdd/assessmentToggler', disableAssessmentControls);
}

/**
* @description Disables assessment component on click while server side loading template
* so user can change his choice somewhere between.
* Handles '/updater/beforeAdd/assessmentToggler' event
* The method sets 'disabled' value to disabled attribute on all '.m-options'
* elements within current .m-assessment-options element
* @param {Object} context Element which was passed as a parameter to $.publish() method
*/
function disableAssessmentControls(context) {
  var optionAreaElem = $(context).parents(options.containerSelector)
                          .find(options.selector)
                          .find(options.optionsAreaSelector)
  optionAreaElem.addClass(DISABLED);
  optionAreaElem.find('input:radio:not(:checked)').attr(DISABLED, true);
}

/**
 * @description Handles '/updater/add/assessmentToggler' event
 * @param {Object} context Element which was passed as a parameter to $.publish() method
 */
function sync(context) {
  let parentContainer = $(context).parents(options.containerSelector);
  toggleControl(parentContainer.length
    ? parentContainer
    : $(context).find(options.containerSelector));
}

/**
* @description Sets 'disabled' value to disabled attribute on all '.m-options'
* elements within passed containers if that containers contain .m-updater-saved elements.
* If there are no .m-updater-saved children elements it resets desabled attribute.
* @param {JQuery} containers List of .m-list-with-assessments-container elements
*/
function toggleControl(containers) {
  containers.each((index, container) => {
    var el = $(container).
      find(options.selector).
      find(options.optionsAreaSelector);
    if ($(container).find('.m-updater-saved').length > 0) {
      el.addClass(DISABLED);
      el.find('input:radio:not(:checked)').attr(DISABLED, true);
      el.find('input').attr('tabindex', -1);
    } else {
      el.removeClass(DISABLED);
      el.find('input').removeAttr(DISABLED);
      el.find('input').removeAttr('tabindex');
    }
  });
}

module.exports = {
  selector: options.selector,
  init: init
};
