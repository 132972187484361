/**
 * Button:
 * Adds extra functionality on buttons marked with m-button-js and m-button-js-click-once class
 */

'use strict';

const buttonSelector = '.m-button-js';
const clickOnceClass = 'm-button-js-click-once';

/**
 * @description Initializes component
 * Subscribes on '/updater/add/button' event
 */
function init() {
  $.subscribe('/updater/add/button', add);
}

/**
 * @description Subscribes 'click' event on all buttons in passed jQuery context
 * @param {jQuery}  @context Context of event
 */
function add($context) {
  $context.on('click', buttonSelector, moduleClickHandler);
}

/**
 * @description Handles 'click' event on a button
 * Sets 'disabled' property to true if vutton has class m-button-js-click-once
 */
function moduleClickHandler() {
  if ($(this).hasClass(clickOnceClass) && $(this).is(':button')) {
    $(this).prop('disabled', true);
  }
}

module.exports = {
  selector: buttonSelector,
  init: init
};
