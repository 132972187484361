modal = require('./modal')

class AdditionalDocAttachment
  constructor: (@$attachmentsContainer) ->
    @$attachmentsContainer.find('.delete-attachment-link').on 'click', @handleDelete

  handleDelete: (e) =>
    $attachmentContainer = $(e.target).closest('.file-upload-field')
    $attachmentContainer.find('.delete-attachment-checkbox').val(true)
    $attachmentContainer.find('.js-existing-file-name').remove()
    $attachmentContainer.find('.uploader-container').removeClass('hide')
    modal.resize()

module.exports = AdditionalDocAttachment
