/*!--------------------------------------------------------
 * selectbox
 * --------------------------------------------------------
 * @depends jquery.js
 * @depends jquery.pubsub.js
 * @depends jquery.ui.selectmenu.js
 * @depends jquery.scrollintoview.js
 */
module.exports = (function($, undefined) {
  'use strict';

  // settings
  var s = {
    locale: 'en',
    selector: 'select:not(.no-selectbox)',
    options: {
      style: 'dropdown',
      open: focus,
      close: close,
      positionOptions: {
        offset: '0 6'
      }
    }
  };

  /* --------------------------------------------------------
   * init
   * --------------------------------------------------------
  */
  function init(opts) {
    // recursively merge opts into settings
    $.extend(true, s, opts);
    s.locale = opts.locale || s.locale;

    $.subscribe('/updater/add', add);

    // specific subscription for when module
    // hasn't been initialised but is necessary
    // within generic /add's context. generic
    // /add inits module and then publishes
    // specific subscription
    $.subscribe('/updater/add/selectbox', add);

    // destroys selectmenus within context
    // (removes any external elements that may
    // have been added to the page)
    $.subscribe('/updater/destroy', function(context) {
      $(context).find(s.selector).selectmenu('destroy');
    });

    overrideSelectmenuClose();
  }

  /* --------------------------------------------------------
   * add
   * --------------------------------------------------------
   * adds selectbox functionality to context
  */
  function add(context) {
    var cont = $(context);

    if(cont.find(s.selector).hasClass('reduced-selectmenu')){
      s.options.width = 110;
    }
    // need to loop through so that we can attach
    // focus and blur events to the 'button' el of
    // each custom selectmenu
    cont.find(s.selector).each(function() {
      var select = $(this).selectmenu(s.options),
          data = select.data('selectmenu'),
          button = data.newelement,
          hiddenParents = data.element.parents('.hide');
      // if the context is a jQuery ui widget then
      // append the selectmenu to the widget
      // this is to stop widgets closing because they close
      // when events are fired on elements outside of them
      if (cont.hasClass('ui-widget')) {
        data.listWrap.appendTo(context);
      }

      // Move the select box off the page
      select.css({
        position: 'absolute',
        visibility: 'hidden'
      });

      button.on('focusin.selectbox', focus);
      button.on('focusout.selectbox', blur);

      // selectmenu plugin calculates width of
      // hidden elements wrong so we need
      // to work it out ourselves by making
      // jquery think the select box is visible
      hiddenParents.css({
        'position': 'absolute',
        'display': 'block',
        'visibility': 'hidden'
      }).removeClass('hide');

      if(!cont.find(s.selector).hasClass('reduced-selectmenu')){
        button.width(data.element.outerWidth());
      }


      hiddenParents.removeAttr('style').addClass('hide');

      // add span to arrow button so we can
      // overlay png on top of theme colour
      button.find('.ui-icon').append('<span />');

      // REP-1899: The selectmenu gets disabled sometimes,
      // so we need to re-enable it, just in case.
      select.selectmenu('enable');
    });
  }

  /* --------------------------------------------------------
   * close
   * --------------------------------------------------------
   * called when selectbox is closed
  */
  function close(e) {
    var el = $(e.currentTarget).data('selectelement');

    // because we always simulate focus for the button
    // on open we need to simulate blur only when
    // they're clicking away from the selectbox
    if (!el || (el && el[0] !== this)) {
      blur.call(this, e);
    }
  }

  /* --------------------------------------------------------
   * focus
   * --------------------------------------------------------
   * called when selectbox is focused
  */
  function focus(e) {
    var button = $(this),
        inst = button.data('selectmenu');

    e = $.extend({}, e, { type: 'focusin'});

    if (inst && inst.listWrap) {
      inst.listWrap.css('min-width', button.outerWidth());
      $.publish('/updater/add', [inst.listWrap]);
      $.publish('/selectbox/focus', [e, inst.listWrap]);
    } else {
      button.data('selectelement').trigger('focusin');
    }
  }

  /* --------------------------------------------------------
   * blur
   * --------------------------------------------------------
   * called when selectbox is blurred
  */
  function blur(e) {
    e = $.extend({}, e, { type: 'focusout'});
    $.publish('/selectbox/blur', [e]);
  }

  /* --------------------------------------------------------
   * overrideSelectmenuClose
   * --------------------------------------------------------
   * use to make sure selectmenu dropdown only closes when
   * a click is outside of the dropdown or on one of it's
   * options (stops closing when using scrollbar)
  */
  function overrideSelectmenuClose() {
    $.ui.selectmenu.originalClose = $.ui.selectmenu.prototype.close;

    $.ui.selectmenu.prototype.close = function(e, retainFocus) {
      if (e) {
        var trigger = $(e.target),
            isOption = trigger.closest('.' + this.widgetBaseClass + '-menu-dropdown').length,
            isOutside = !trigger.closest('.' + this.widgetBaseClass + '-menu').length;

        if (isOption || isOutside) {
          $.ui.selectmenu.originalClose.call(this, e, retainFocus);
        }
      } else {
        $.ui.selectmenu.originalClose.call(this, e, retainFocus);
      }
    }
  }

  return {
    selector: s.selector,
    init: init
  }

}(jQuery));
