'use strict';

/**
 * Note: SessionTimer and this library work separately each one having their own counter...
 */

const s = {
  locale: 'en',
  selector: '.m-modal-timeout',
  noSubmitSelector: '#confirmation',
  notifyMSBeforeTimeout: 60000,
  open: false,
  deferredTime: 150,
  options: {
    inline: true,
    href: '.m-modal-timeout-content',
    width: '620px',
    height: '160px',
    opacity: 0.2,
    initialWidth: '620px',
    initialHeight: '160px',
    overlayClose: false,
    open: true,
    onClosed: function(){
      close();
    }
  }
};

function init(opts) {
  $.extend(true, s, opts);
  $.subscribe('/updater/add/modalSessionTimeout', add);
}

function add() {
  startTimer();
}

function startTimer() {
  const $modal = $(s.selector);
  const sessionTime = $modal.data('timeout') * 1000;
  const openTime = sessionTime - s.notifyMSBeforeTimeout;
  let sessionTimeout;
  let openTimeout;
  let resetSubscription;
  let submitSubscription;

  function reset() {
    clearTimeout(openTimeout);
    clearTimeout(sessionTimeout);
    $.unsubscribe(submitSubscription);
    $.unsubscribe(resetSubscription);
    startTimer();
  }

  submitSubscription = $.subscribe('/updater/submit', reset);
  resetSubscription = $.subscribe('/sessionTimer/reset', reset);

  openTimeout = setTimeout(open, openTime);
  sessionTimeout = setTimeout(timeout, sessionTime);

  /*
   * REP-5605 Commenting out to test if this solely solves the
   * IE timeout issue.
   */
  //window.addEventListener('storage', refreshSession);
}

function open() {
  s.open = true;
  const $modalContent = $(s.options.href).removeClass('hide');
  const willSave = !$(s.noSubmitSelector).length;
  const continueMethod = willSave ? saveAndContinue : closeAndContinue;
  $modalContent.on('click', '.continue', function (e) {
    const $this = $(this);
    const enable = $.proxy($this.prop, $this, 'disabled', false);
    $this.prop('disabled', true);
    e.preventDefault();
    continueMethod().always(enable);
  });
  $(s.selector).colorbox(s.options);
}

function close() {
  if (s.open) {
    $(s.options.href).addClass('hide');
    $.colorbox.close();
    s.open = false;
  }
}

function timeout() {
  $.publish('/updater/expired');
}

/**
 * @description Save the form via ajax request, fallbacks to full page reload form submission
 * @returns {Promise}
 */
function saveAndContinue() {
  const $timeout = $.Deferred();

  setTimeout(function(){
    const form = $('.report form');
    $.post(form.attr('action'), form.serialize())
      .done(function () {
        $.publish('/sessionTimer/reset');
        close();
        $timeout.resolve(arguments);
      })
      .fail(function () {
        // Something went wrong, let's try refreshing the page.
        window.onbeforeunload = null;
        form.submit();
        $timeout.reject(arguments);
      });
  }, s.deferredTime);
  return $timeout.promise();
}

/**
 * @description Call a dummy URL via ajax to refresh the session, fallbacks to full page reload
 */
function closeAndContinue() {
  return $.get('/')
    .done(function () {
      $.publish('/sessionTimer/reset');
      close();
    })
    .fail(function () {
      // Something went wrong, let's trying refreshing the page.
      window.onbeforeunload = null;
      setTimeout(window.reload.bind(window), 300);
    });
}

/*
 * REP-5605 Commented out to test
function refreshSession(event) {
  if (event.key === 'refreshTimeout') {
    closeAndContinue();
  }
}
*/

module.exports = {
  selector: s.selector,
  init: init
};
