/*!--------------------------------------------------------
 * modal
 * --------------------------------------------------------
 * @depends jquery.js
 * @depends jquery.pubsub.js
 * @depends jquery.colorbox.js
 */
module.exports = (function($, undefined) {
  'use strict';

  // settings
  var s = {
    locale: 'en',
    selector: '.m-modal',
    element: '#colorbox',
    contentSelector: undefined,
    created: false,
    options: {
      opacity: .6,
      initialHeight: 0,
      initialWidth: 0,
      outerWidth: 720,
      closeButton: false
    }
  };

  /* --------------------------------------------------------
   * init
   * --------------------------------------------------------
  */
  function init(opts) {
    // recursively merge opts into settings
    $.extend(true, s, opts);
    s.locale = opts.locale || s.locale;

    s.contentSelector = s.selector + '-content';
    s.closeButton = $('#cboxClose');

    $.subscribe('/updater/add', add);
    $.subscribe('/updater/add/modal', add);
    $.subscribe('/modal/update', resize);
  }

  /* --------------------------------------------------------
   * add
   * --------------------------------------------------------
   * adds modal functionality to context
  */
  function add(context) {
    $(context).find(s.selector).each(function() {
      var button = $(this),
          opts = { inline: true },
          data = button.data(),
          noClose = (data.modalNoClose !== undefined),
          isInsta = (data.modalInsta !== undefined),
          my_selector,
          content;

      // trying to open .m-modal-content
      if (!isInsta && !this.href) {
        my_selector = button.data('modal-content-selector');
        if(my_selector !== undefined) {
          content = $(my_selector)
        }
        else {
          content = button.find(s.contentSelector);
          content = content.length ? content : content.nearest(s.contentSelector);
        }

        if (content.length && content[0].tagName === 'SCRIPT') {
          content = $('<div />', {
            'class': s.contentSelector.slice(1),
            'html': content.html()
          });
        }
        opts.href = content;
      }

      // trying to open an iframe
      if (button.data('modal-width') || button.data('modal-height')) {
        opts = {
          iframe: true,
          fastIframe: false,
          innerWidth: button.data('modal-width'),
          innerHeight: button.data('modal-height')
        };
        button.data(opts);
      }

      opts.onOpen = onOpen;
      opts.onComplete = onComplete;
      opts.onClosed = onClose;
      opts.overlayClose = opts.escKey = opts.close = !noClose;

      if (isInsta) {
        // immediately invoking modal
        opts.href = button.remove().css('display', 'block');
        $.colorbox($.extend({}, s.options, opts));
      } else {
        // initialises jQuery colorbox plugin with any
        // customisations passed via data attributes
        button.colorbox($.extend({}, s.options, opts));
      }

      fixTabIndex();

      getElement().on('click', '.m-modal-close', close);
    });
  }

  /* --------------------------------------------------------
   * open
   * --------------------------------------------------------
  */
  function open(opts) {
    if (opts.html) {
      // wrap given html with content element if not done yet
      if (!$('<div class="' + s.contentSelector.slice(1) + '">').html(opts.html).find(s.contentSelector).length)
        opts.html = $('<div class="' + s.contentSelector.slice(1) + '">').html(opts.html);
    }

    var newOpts = $.extend({}, s.options, opts);
    $.colorbox(newOpts);

    if (!s.created && newOpts.overlayClose) {
      if (newOpts.overlayClose) {
        $('body').on('click', '#cboxOverlay', onClose);
      }
      else if (newOpts.close) {
        $('body').on('click', '#cboxClose', onClose);
      }

    }
    s.created = true;
  }

  /* --------------------------------------------------------
   * close
   * --------------------------------------------------------
  */
  function close() {
    $.colorbox.close();
    $.publish('/modal/close');
  }

  /* --------------------------------------------------------
   * onOpen
   * --------------------------------------------------------
  */
  function onOpen() {
    s.closeButton.hide();
    $.publish('/modal/open', [getElement()]);
  }

  /* --------------------------------------------------------
   * onComplete
   * --------------------------------------------------------
  */
  function onComplete() {
    s.closeButton.show();
    $.publish('/modal/complete', [getElement()]);
  }

  /* --------------------------------------------------------
   * onClose
   * --------------------------------------------------------
  */
  function onClose() {
    $.publish('/modal/close');
  }

  /* --------------------------------------------------------
   * resize
   * --------------------------------------------------------
  */
  function resize() {
    try {
      var el = $.colorbox.element(); 
    } catch (error) {
      var el = $();
    }
    if (!el.data('iframe')) {
      $.colorbox.resize();
      $.publish('/modal/resize');
    }
  }

  /* --------------------------------------------------------
   * getCloseButton
   * --------------------------------------------------------
  */
  function getCloseButton() {
    return s.closeButton;
  }

  function getElement() {
    return s.el || $(s.element);
  }

  function fixTabIndex() {
    var modal = getElement(),
        overlay = $('#cboxOverlay');
    modal.detach();
    modal.prependTo('body');
    overlay.detach();
    overlay.prependTo('body');
  }

  return {
    selector: s.selector,
    init: init,
    getCloseButton: getCloseButton,
    open: open,
    close: close,
    resize: resize
  };

}(jQuery));
