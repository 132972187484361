/**
 * @description: When the element is clicked some other elements will automatically unchecked
 * data-reset takes a string/list of strings, separated by coma
 * data-resetable takes a string as an identifier
 *
 * @caution: The module should be wrapped with a fieldset to work properly
 *
 * @example:
 * If the element checked has a data-reset attribute and the value is 'serious' then all the elements with the
 * data-resetable attribute with 'serious' as a value will be unchecked
 *
 * @usage:
 * <fieldset>
 *    <input type="checkbox" class="m-conditional-multi-choices" data-reset="serious, bae" data-resetable="not-serious">
 *    <input type="checkbox" class="m-conditional-multi-choices" data-resetable="bae">
 *    <input type="checkbox" class="m-conditional-multi-choices" data-resetable="serious">
 * </fieldset>
 *
 * Clicking the first input will uncheck the second and third elements because their
 * `data-resetable` value is contained in the first element's `data-reset`
 *
 */

'use strict';

var settings = {
  selector: '.m-conditional-multi-choices:input',
  // assumes that all the modules are wrapped with a fieldset
  scopeSelector: 'fieldset'
};

function init(opts) {
  $.extend(true, settings, opts);
  $.subscribe('/updater/add/conditionalMultiChoices', add);
}

function add(context) {
  $(context).on('check.options', settings.selector, handleResets);
}

function handleResets() {
  var $trigger = $(this),
    toReset = $trigger.data('reset').split(/[\s,]+/),
    $scope = $(this).closest(settings.scopeSelector);

  if (!toReset.length) {
    return;
  }

  $scope.find(settings.selector).each(function (idx, el) {
    if (el != $trigger[0] && $(el).is(':checked') && $.inArray($(el).data('resetable'), toReset) !== -1) {
      $(el).trigger('uncheck');
    }
  });
}

module.exports = {
  init: init
};
