$ ->
  $el = $('.header-sticky')
  breakpoint = $el.position().top # needs to be set beforehand

  $(window).scroll ->
    $('.header-sticky-placeholder').css({'height':$el[0].getBoundingClientRect().height})
    if $(this).scrollTop() > breakpoint
      $el.addClass('stuck')
    else
      $el.removeClass('stuck')
