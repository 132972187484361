module.exports = (function ($) {
  "use strict";

  var s = {
        locale: "en",
        selector: "[type='submit'][form]"
      };

  function init(opts) {
    $.subscribe("/updater/add/externalSubmittion", add);
  }

  function add(context) {
    context.on("click", s.selector, {context: context}, onClick);
  }

  function onClick(e) {
    var $this = $(this),
        $form = $("#" + $this.attr("form"));
    e.preventDefault();
    $form.submit();
  }

  return {
    selector: s.selector,
    init: init
  }

}(jQuery));

