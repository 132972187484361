/**
 * yearOfBirth:
 * @description Attach a on blur event to the elements to enable auto date formating
 */
module.exports = (function ($, I18n) {
  'use strict';

  var s = {
    selector: '.m-year-of-birth',
    unknown: '.year-of-birth-unknown',
    min: 150
  };

  function init(opts) {
    $(s.selector).on('blur', onBlur);
    $(s.unknown).on('change', onClickUnknown);
  }

  function onClickUnknown(e) {
    $.publish('/updater/error/remove', [I18n.t('javascript.calendar_error'), $(s.selector)]);
  }

  function onBlur(e) {
    var $input = $(this),
      value = $input.val();

    if (!value) {
      $.publish('/updater/error/remove', [I18n.t('javascript.calendar_error'), $input]);
      return;
    }

    if (isNaN(value)) {
      $.publish('/updater/error/add', [I18n.t('javascript.calendar_error'), $input, null]);
      return false;
    }

    var year = extendYear(parseInt(value, 10));

    if (validate(year)) {
      $input.val(year);
      $.publish('/updater/error/remove', [I18n.t('javascript.calendar_error'), $input]);
    }
    else {
      $.publish('/updater/error/add', [I18n.t('javascript.calendar_error'), $input, null]);
    }
  }

  function extendYear(year) {
    var currentYear = new Date().getFullYear(),
      century = Math.floor(currentYear / 100),
      newYear = isLessThanMinYear(currentYear, year) ? century * 100 + year : year;

    return isNotFuture(currentYear, newYear) ? (century - 1) * 100 + year : newYear;
  }

  function validate(year) {
    var now = new Date(),
      currentYear = now.getFullYear();
    return !isNotFuture(currentYear, year) && !isLessThanMinYear(currentYear, year);
  }

  function isLessThanMinYear(currentYear, userInput) {
    return currentYear - userInput > s.min;
  }

  function isNotFuture(currentYear, userInput) {
    return userInput > currentYear;
  }

  return {
    selector: s.selector,
    init: init,
    validate: validate
  };

}(jQuery, window.I18n));
