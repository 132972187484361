module.exports = (function ($) {

  var s = {
    selector: '.m-autosize'
  };

  function AutoSize(context, input) {
    this.context = context;
    this.input = input;
    this.keepOpenToggler = this.input.siblings('.m-autosize-keep-open').find('input');
    this.keepOpen = false;
    this.sync();
    this.handleKeepOpen();
    this.bind();
  }

  $.extend(AutoSize.prototype, {
    sync: function () {
      this.height = this.input.height();
    },

    bind: function () {
      this.keepOpenToggler.change($.proxy(this.handleKeepOpen, this));
      if (!this.keepOpenToggler.length) {
        this.input.focus($.proxy(this.handleFocus, this));
        this.input.blur($.proxy(this.handleBlur, this));
      }
    },

    handleFocus: function () {
      if(this.keepOpen) {
        return;
      }
      this.input.autosize();
      this.input.trigger('autosize.resize');
    },

    handleBlur: function () {
      if(this.keepOpen) {
        return;
      }
      this.input.trigger('autosize.destroy');
      this.input.height(this.height);
    },

    handleKeepOpen: function () {
      if (this.keepOpenToggler.is(':checked')) {
        this.handleFocus();
        this.keepOpen = true;
      }
      else {
        this.keepOpen = false;
        this.handleBlur();
      }
    }
  });

  AutoSize.factory = function (context) {
    var inputs = [];
    context.find(s.selector).each(function () {
      inputs.push(new AutoSize(context, $(this)));
    });
    return inputs;
  };

  function init() {
    $.subscribe('/updater/add/autosize', AutoSize.factory);
  }

  return {
    selector: s.selector,
    init: init
  };

}(jQuery));
