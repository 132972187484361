/*!--------------------------------------------------------
 * options
 * --------------------------------------------------------
 * @depends jquery.js
 * @depends jquery.pubsub.js
 */
module.exports = (function($, undefined) {
  'use strict';

  // settings
  var s = {
    locale: 'en',
    selector: '.m-options',
    button: '.m-button'
  };

  /* --------------------------------------------------------
   * init
   * --------------------------------------------------------
  */
  function init(opts) {

    opts = opts || {};

    // recursively merge opts into settings
    $.extend(true, s, opts);
    s.locale = opts.locale || s.locale;

    $.subscribe('/updater/add', add);
    $.subscribe('/updater/add/options', add);
  }

  /* --------------------------------------------------------
   * add
   * --------------------------------------------------------
   * adds options functionality to context
  */
  function add(context) {
    var context = $(context),
        options = context.find(s.selector);
    if(!$('.elm-realm').length && !$('.contact-centre').length) {
      options.each(tidyButtons);
    }
    options.find(':input').each(setup);
    options.on('check.options uncheck.options', ':input', setup);
  }

  function setup(e) {
    var option = $(this),
        lltCode = option.data('meddra-llt-code'),
        checked = e && e.type ? e.type === 'check' : option.is(':checked'),
        parent = option.parents(s.selector),
        checkedField = parent.find('input:checked[data-meddra-llt-code]').not(this);
    parent.find('.llt-code').val(checked && lltCode ? lltCode : checkedField.data('meddra-llt-code'));
  }

  /* --------------------------------------------------------
   * tidyButtons
   * --------------------------------------------------------
   * makes buttons the same width as the widest button
  */
  function tidyButtons() {
    var options = $(this),
        tmp = $('<div />').append(options.clone()),
        tmpButtons = tmp.find(s.button),
        buttons = options.find(s.button),
        maxWidth = 0,
        width;

    if (buttons.hasClass('tidy')){
      return;
    }
    // we need to use this tmp
    // element because the existing
    // options element might be hidden
    // and we cannot get the .width()
    // of hidden elements.
    tmp.css({
      position: 'absolute',
      top: '-9999em',
      left: '-99999em',
      width: '100%'
    }).appendTo('#body');

    tmpButtons.each(function(i, button) {
      width = $(this).outerWidth()+5;
      if (width > maxWidth) {
        maxWidth = width;
      }
    });

    buttons.addClass('tidy');
    buttons.width(maxWidth);
    tmp.remove();
  }

  return {
    selector: s.selector,
    init: init
  };

}(jQuery));
