/**
 * Jump To:
 * @description Attach a click event to the elements and clicking it scroll to an other element
 * @usage The element should have a data-id attribute that should match the element we want to scroll to
 * @publish 'jump-to/failed' if the element to scroll to was not found in the DOM
 * @publish 'jump-to/jumped' if the scroll to the element successfully happened
 */
module.exports = (function ($) {
    'use strict';

    var s = {
        locale: 'en',
        selector: '.m-jump-to',
        // the selector for fixed header if any
        fixedHeader: '',
        // whether jumping to the item should be animated
        animate: false
    };

    function init(opts) {
        $.extend(true, s, opts);

        $('body').on('click', s.selector + ' a', onClick);
    }

    function onClick(e) {
        var headerHeight = 0,
          toId = $(e.target).data('id'),
          $to = $('#' + toId);

        if (!$to.length) {
            $.publish('jump-to/failed', [toId]);
            return;
        }

        if (s.fixedHeader) {
            headerHeight = $(s.fixedHeader).outerHeight();
        }

        var scrollTo = $to.offset().top - headerHeight;

        if (s.animate) {
            $('html, body').animate({scrollTop: scrollTo}, 200);
        }
        else {
            $('html, body').scrollTop(scrollTo);
        }

        $.publish('jump-to/jumped', [$to]);

        return false;
    }

    return {
        selector: s.selector,
        init: init,
        onClick: onClick
    };

}(jQuery));
