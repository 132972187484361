'use strict';

module.exports = (function ($) {
  var settings = {
        selector: '.reporter-details-block'
      }

  function init(opts){
    // NOTE: Trigger `change` to let "toggle" to set correct state for details field.
    $('.reporter-details-block select').on('change', onReporterTypeChange).trigger('change');
  }

  function onReporterTypeChange(event){
    var $select = $(event.target),
      selectedType = $select.find('option:selected').val(),
      detailsLabels = $('.reporter-details-block').data('details-labels');

    $(".reporter-details-block > .reporter-type-details")
      .find("label > b")
      .replaceWith(`<b> ${detailsLabels[selectedType]} &nbsp;*</b>`);

    // HACK: When hidden, textarea is not able to get its minimum height. But default value set us 71px.
    //       To display field as required, force auto-size calculation when linked select value is changed.
    //       0 delay here is to place this call outside of workflow (change label, show/hide input, etc).
    setTimeout(function(){
      $(".reporter-details-block > .reporter-type-details").find('textarea').trigger('input');
    }, 0);
  }

  return {
    selector: settings.selector,
    init: init
  };
}(jQuery));
