module.exports = (function ($) {
  'use strict';

  var s = {
      locale: 'en',
      selector: 'input[type="radio"][value="death"], input[type="checkbox"][name*="[death]"]',
      containerSelector: '#outcome-death',
      removeSelector: '#outcome-death-remove',
      container: null,
      field: null,

      buttonsSelector: '.m-options label',

      dateOfDeathSelector: '#date-of-death',
      dateOfDeath: null,

      causeOfDeathSelector: '#cause-of-death',
      causeOfDeath: null,

      autosuggestControlSelector: '.autosuggest-control',
      autosuggestControl: null,

      autopsyPerformedSelector: '.autopsy-performed',
      autopsyPerformed: null,

      autopsyDetailsAvailableSelector: '.autopsy-details-available',
      autopsyDetailsAvailable: null,

      autopsyCauseOfDeathSelector: '.autopsy-cause-of-death',
      autopsyCauseOfDeath: null

    },
    DISABLED = 'disabled',
    HIDE = 'hide',
    INPUT_SELECTOR = ':input',
    VISIBLE_SELECTOR = ':visible',
    HIDDEN_SELECTOR = '[type="hidden"]';

  function init(opts) {
    $.subscribe('/updater/add/deathToggler', add);
    $.subscribe('/updater/remove_template', add);
  }

  function add(context) {
    context = $(context);

    s.container = context.find(s.containerSelector);
    s.remove_container = context.find(s.removeSelector);
    s.field = s.container.find('.m-updater').data('field');

    // Find the various parts of this component
    s.dateOfDeath = s.container.find(s.dateOfDeathSelector);
    s.causeOfDeath = s.container.find(s.causeOfDeathSelector);
    s.autosuggestControl = s.causeOfDeath.find(s.autosuggestControlSelector);
    s.autopsyDetailsAvailable = s.container.find(s.autopsyDetailsAvailableSelector);
    s.autopsyPerformed = s.container.find(s.autopsyPerformedSelector);
    s.autopsyCauseOfDeath = s.container.find(s.autopsyCauseOfDeathSelector);

    multiToggle(context);

    var deathButtonLabels = context.find(s.selector).parents('label');
    // HIDES the death toggle component when the label 'death' is selected from the
    // group of radio buttons
    deathButtonLabels.on('check.options uncheck.options', INPUT_SELECTOR, function () {
      nextTick($.proxy(multiToggle, null, context));
    });

    // SHOWS the death toggle component when the label 'death' is selected from the
    // group of radio buttons
    deathButtonLabels.one('check.options uncheck.options', INPUT_SELECTOR, function () {
      nextTick($.proxy(enableOptions, null, null));
    });
    enableOptions()
  }

  function nextTick(fn) {
    setTimeout(fn, 0);
  }

  /*
   * Opens or Closes the Death Details pane if the Death value is checked.
   */
  function multiToggle(context) {
    var numOfDeathButtonsPressed = context.find(s.selector + ':checked').length;

    if (numOfDeathButtonsPressed > 0) {
      show(s.container);
      disableHiddenInputs(s.remove_container);
    } else {
      hide(s.container);
      enableHiddenInputs(s.remove_container);
    }
    enableOptions();
  }

  function enableOptions() {
    enableInputs(s.dateOfDeath);
    enableInputs(s.autosuggestControl);
    enableInputs(s.autopsyPerformed);
    enableInputs(s.autopsyDetailsAvailable);

    enableHiddenInputs(s.dateOfDeath);
  }

  function enableInputs(el) {
    el.find(INPUT_SELECTOR).filter(VISIBLE_SELECTOR).prop(DISABLED, false);
    el.find(s.buttonsSelector).filter(VISIBLE_SELECTOR).removeClass(DISABLED);
  }

  function enableHiddenInputs(el) {
    el.find(INPUT_SELECTOR).filter(HIDDEN_SELECTOR).prop(DISABLED, false);
  }

  function disableHiddenInputs(el) {
    el.find(INPUT_SELECTOR).filter(HIDDEN_SELECTOR).prop(DISABLED, true);
  }

  function show(el) {
    el.removeClass(HIDE);
  }

  function hide(el) {
    el.addClass(HIDE);
  }

  return {
    selector: s.selector,
    init: init
  };

}(jQuery));
