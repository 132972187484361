/*!--------------------------------------------------------
 * toggler
 * --------------------------------------------------------
 * @depends jquery.js
 * @depends jquery.pubsub.js
 * @depends jquery.scrollintoview.js
 */
/**
 * Documentation in progress
 *
 * Attribute configuration:
 * - [data-toggle]: Selector - the selector to the element to toggle
 * - [data-toggle-type]: Type - The type of toggler, see s.type for all options
 * - .m-toggle-remove:
 * - .m-toggle-dont-hide:
 * - [data-toggle-target-type]: Type - same as data-toggle-type but to be used on the target
 * - .m-toggle-scroll:
 * - .m-toggle-self-disable: If present the button clicked will be disabled
 *
 * Use:
 * The below would disable the button when checking the radio
 *     <input type="radio" class="m-toggle" data-toggle="#to_be_disabled" data-toggle-type="disabled">
 *     <button id="to_be_disabled">
 *
 */
'use strict';
module.exports = (function($, undefined) {
  // settings
  var s = {
    locale: 'en',
    selector: '.m-toggle',
    name: 'toggler',
    types: [
      'slide',
      'hide',
      'disabled'
    ],
    ignore: [
      '.ruby-remove',
      '.has-toggle :input',
      '.m-autosuggest-create'
    ]
  };

  /* --------------------------------------------------------
   * init
   * --------------------------------------------------------
  */
  function init(opts) {
    // recursively merge opts into settings
    $.extend(true, s, opts);
    s.locale = opts.locale || s.locale;

    $.subscribe('/updater/add', add);

    // specific subscription for when module
    // hasn't been initialised but is necessary
    // within generic /add's context. generic
    // add inits module and then publishes
    // specific subscription
    $.subscribe('/updater/add/' + s.name, add);
    $.subscribe('/updater/destroy/' + s.name, destroy);
  }

  /* --------------------------------------------------------
   * add
   * --------------------------------------------------------
   * adds toggler functionality to context
  */
  function add(context) {
    context = $(context);
    // disable fields within hidden/disabled elements
    $.each(s.types, function(i, type) {
      context
        .find('.' + type + ' :input')
        .not(s.ignore.join(','))
        .prop('disabled', true);
    });

    // setup togglers
    context.findMe(s.selector).not('[data-field]').each(function(i, t) {

      var toggler = $(t),
          toggleSelector = toggler.data('toggle'),
          inst = {
            type: (toggler.data('toggle-type') || s.types[0]),
            all: !!toggler.data('toggle-all'),
            elements: []
          },
          onEvent;

      if (toggler.is('.ui-selectmenu-menu *, .ui-selectmenu')) return;

      if (inst.all) inst.elements = context.find(toggleSelector);
      if (!inst.elements.length) inst.elements = toggler.nearest(toggleSelector);

      onEvent = (function() {
        switch (t.tagName) {
          case 'INPUT':
            return 'check.' + s.name + ' uncheck.' + s.name;

          case 'OPTION':
            inst.value = toggler.val();
            toggler = toggler.parent();
            return 'change.' + s.name;

          default:
            return 'click.' + s.name;
        }
      })();

      // event delegation only works with a child selector as a String
      // instead check for disabled class on computation
      toggler
        .on(onEvent, {inst: inst}, ifNotDisabled(toggleClick))
        .on(onEvent, {inst: inst}, ifNotDisabled(selfDisable))
        .on('keydown.' + s.name, ifNotDisabled(keydown))
        .data(s.name, inst);
    });

    setTimeout($.proxy(initToggles, null, context), 0);
  }

  /**
   * Check if the element has class disabled before executing handler
   * @param fn - handler
   */
  function ifNotDisabled(fn) {
    return function () {
      if (!$(this).hasClass('.disabled')) {
        fn.apply(this, arguments);
      }
    }
  }

  function initToggles(context) {
    context.find(':radio:checked, :checkbox:checked').each(function() {
      var field = $(this),
          isDisabled = field.is(':disabled');

      if (isDisabled) {
        field.prop('disabled', false);
      }

      field.trigger('check', [true]);

      if (isDisabled) {
        field.prop('disabled', true);
      }
    });

    context.find('select.select-toggle').trigger('change');
  }

  /* --------------------------------------------------------
   * destroy
   * --------------------------------------------------------
   * removes toggler functionality
  */
  function destroy(context) {
    $(context).findMe(s.selector).each(function(i, t) {
      var toggler = $(t),
          inst = toggler.data(s.name),
          isInput = (t.tagName === 'INPUT'),
          wasToggled = false;

      if (inst && inst.isToggled) {
        toggler.trigger(isInput ? 'uncheck' : 'click');
        wasToggled = true;
      }

      $(t).removeData(s.name).unbind('.' + s.name);

      if (wasToggled) {
        toggler.trigger(isInput ? 'check' : 'click');
      }
    });
  }

  /* --------------------------------------------------------
   * setup
   * --------------------------------------------------------
   * adds toggle class and data-toggle attribute to
   * @param context (or @param toggler within @param context
   * if passed) so that they can be turned into toggles
   * via add()
  */
  function setup(context, elements, toggler, type) {
    var c = $(context),
        element = toggler ? c.find(toggler) : c;

    element
      .addClass(s.selector.slice(1))
      .data('toggle-type', type || s.types[0])
      .data('toggle', c.data('toggle') || elements);

    add(context);
  }

  /* --------------------------------------------------------
   * toggleClick
   * --------------------------------------------------------
  */
  function toggleClick(e, setup) {
    var toggler = $(this),
        inst = e.data.inst;

    // if we're clicking an element within the toggler
    // we shouldn't execute toggler functionality
    if ($(e.target).parents(s.selector)[0] === toggler[0]) {
      return;
    }

    if(toggler.hasClass('m-toggle-dont-hide')) {
      inst.elements = inst.elements.filter('.hide')
    }

    toggle(toggler, inst, inst.elements, setup);
    e.preventDefault();
  }

  function selfDisable() {
    var $toggler = $(this);
    if($toggler.hasClass(s.selector.slice(1) + '-self-disable')) {
      $toggler.prop('disabled', true);
    }
  }

  /* --------------------------------------------------------
   * toggle
   * --------------------------------------------------------
  */
  function toggle(toggler, inst, elements, setup) {
    var remove = toggler.closest(s.selector + '-remove'),
        scrolled = false,
        isClosed;

    if (inst.value &&
        inst.value !== toggler.val() &&
        inst.value !== inst.prevValue) {
      return;
    } else {
      inst.prevValue = toggler.val()
    }

    // is-toggled flag to determine whether
    // toggle's elements are toggled
    inst.isToggled = !inst.isToggled;

    // if toggle has a remove class
    // then remove it when toggling
    if (remove.length) {
      toggler.blur();
      remove.hide();
    }

    $.each(elements, function(i, element) {
      var elementToggles, toggleType;

      element = $(element);
      elementToggles = element.findMe(s.selector);
      toggleType = element.data('toggle-target-type') || inst.type;
      if (element.is(':animated')) {
        // stop it from animating but don't
        // jump to the end of the animation
        // otherwise it will cause the toggle
        // to close before opening again
        element.stop(true, false);
      }

      if (toggleType === 'slide') {

        // stop UI from jumping around when sliding
        if (element.css('clear') !== 'right') {
          element.css({ clear: 'both' });
        }

        if (element.data('toggle-up') === undefined) {
          element.data('toggle-up', element.is(':hidden'));
        }

        // the toggle-up flag enables us to force
        // slide down when retoggling the same element
        if (!element.data('toggle-up')) {
          element
            .data('toggle-up', true)
            .fadeOut({
              duration: 200,
              queue: false
            })
            .slideUp(200, function() {
              element.addClass('hide');
            });
        } else {
          var hasHide = element.hasClass('hide');

          if (hasHide) {
            element.hide().removeClass('hide');
          }

          element
            .data('toggle-up', false)
            .css('display', (hasHide ? 'none' : 'block'))
            .slideDown(200, function() {
              element.css('height', 'auto');
            })
            .fadeIn({
              duration: 200,
              queue: false
            });
        }
      }

      if (toggleType === 'disabled' && element[0].tagName === 'INPUT' && !element.hasClass('disabled')) {
        element.val(''); // clear input field's value
      }

      if(element.hasClass('disabled')) {
        element.prop("disabled", false);
        element.find('select').first().selectmenu( "enable");
        // something unindentified is setting the select to disabled=disabled
        // the following makes sure that we remove disabled at the end of the execution stack
        setTimeout(function(){
          element.find('select').first().removeAttr('disabled')
        }, 0)
      }
      else if ((element[0].offsetHeight > 1 || element.hasClass('disable-hidden')) && !element.hasClass('slide') && element.data('toggleUp') == undefined){
        element.prop("disabled", true)
        element.find('select').first().selectmenu( "disable")
      }

      element.toggleClass(toggleType);

      // delay scrollintoview incase
      // it was being animated
      if (!setup && !scrolled && element.hasClass(s.selector.slice(1) + '-scroll')) {
        setTimeout(function() {
          if (element.hasClass(toggleType)) {
            element.scrollintoview();
          }
        }, 201);

        scrolled = true;
      }

      isClosed = (toggleType === 'slide' ?
          element.data('toggle-up') : !element.hasClass(toggleType));

      disableOrEnableInputChildren(element, isClosed);

      $.publish('/toggler/' + (isClosed ? 'close' : 'open'), [toggler, element]);

      // focus the element for accessibility
      // if the toggle was removed
      if (!isClosed && remove.length) {
        element.attr('tabindex', -1).focus();
      }

      // the element we are toggling,
      // contains toggles
      if (elementToggles && elementToggles.length) {
        $.each(elementToggles, function(i, el) {
          // if the sub-toggle is toggling the same element as the
          // current toggle don't re-toggle it, otherwise we end up with
          // an endless loop.
          if (toggler.data('toggle') !== $(el).data('toggle')) {
            toggleSubToggle(el);
          }
        });
      }
    });
  }

  function disableOrEnableInputChildren(element, isClosed) {
    var selector = isClosed ? ':input' : '> :input, :visible > :input, :visible > .field_with_errors > :input';
    // Explicitly select input elements within field_with_errors as :visable > :input couldn't find it
    element
      .find(selector)
      .not(s.ignore.join(','))
      .prop('disabled', isClosed);
  }

  /* --------------------------------------------------------
   * toggleSubToggle
   * --------------------------------------------------------
  */
  function toggleSubToggle(el) {
    var subToggler = $(el),
        inst = subToggler.data(s.name),
        subTogglerInput = (el.tagName === 'INPUT');

    if (!inst) {
      return;
    }

    // if this sub toggles element is toggled
    // then we need to untoggle it
    if (inst.isToggled) {
      subToggler.trigger((subTogglerInput ? 'uncheck' : 'click'), [true]);
      inst.wasToggled = true;

    // if this sub toggles element isn't toggled
    // but the sub toggle was toggled before then
    // trigger it's toggle event
    } else if (inst.wasToggled) {
      subToggler.trigger((subTogglerInput ? 'check' : 'click'), [true]);
      inst.wasToggled = false;
    }
  }

  /* --------------------------------------------------------
   * keydown
   * --------------------------------------------------------
  */
  function keydown(e) {
    var toggler = $(this),
        inst = toggler.data(s.name);

    if (toggler.closest('.has-toggle').length && e.keyCode === $.ui.keyCode.ENTER) {

      toggler.trigger(inst.isToggled ? 'uncheck' : 'check');
      e.preventDefault();
    }
  }

  return {
    selector: s.selector,
    init: init,
    setup: setup,
    toggle: toggle
  };

}(jQuery));
