module.exports = (function ($) {

  var s = {
        selector: '.m-target-validation'
      },
      MAXLENGTH = 'maxlength',
      PATTERN = 'pattern',
      REQUIRED = 'required';

  function init(opts) {
    $.subscribe('/updater/add/targetValidation', TargetValidation.factory);
  }

  function TargetValidation(button, context) {
    this.context = context;
    this.button = button;
    this.target = context.find(button.data('target'));
    this.bind();
    this.validate();
  }

  $.extend(TargetValidation.prototype, {
    bind: function () {
      this.target.keyup($.proxy(this.validate, this));
      $.subscribe('/modal/close', $.proxy(this.clear, this));
    },

    clear: function () {
      this.target.val('');
      this.validate();
    },

    validate: function () {
      this.button.prop('disabled', !this.valid());
    },

    valid: function () {
      return this.validMinLength() && this.validMaxLength() && this.validPattern() && this.validRequired();
    },

    validMinLength: function () {
      var target = this.target,
          minLength = target.data('minlength');
      if (minLength) {
        return target.val().length >= minLength;
      }
      return true;
    },

    validMaxLength: function () {
      var target = this.target,
          maxLength = target.attr(MAXLENGTH) || target.data(MAXLENGTH);
      if (maxLength) {
        return target.val().length <= maxLength;
      }
      return true;
    },

    validPattern: function () {
      var target = this.target,
          pattern = target.attr(PATTERN) || target.data(PATTERN),
          regExp = new RegExp(pattern);
      if (pattern) {
        return regExp.test(target.val());
      }
      return true;
    },

    validRequired: function () {
      var target = this.target,
          required = target.prop(REQUIRED) || target.data(REQUIRED);
      if (required) {
        return !!target.val();
      }
      return true;
    }
  });

  TargetValidation.factory = function (context) {
    $.each(context.find(s.selector), function (i, button) {
      new TargetValidation($(button), context);
    });
  };

  return {
    selector: s.selector,
    init: init
  };

}(jQuery));

