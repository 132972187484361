const modal = require('./journey/modal');

$(document).on('click', 'footer [data-modal-url]', function(e) {
  var width = '50%';
  var height = '85%';
  e.preventDefault();
  modal.open({
    inline: false,
    href: $(this).data('modal-url'),
    height: height,
    initialHeight: height,
    width: width,
    initialWidth: width
  });
});
