/**
 * Confirmation:
 * @description: Component which sends an email to specified email address.
 * @example: Html for the component may look like following:
 * <div>
    <button id="send_email_button">Send Email</button>
    <input id="email_address" type="text" value="some@email.com" />
    <div id="error_success">
    </div>
  </div>
 * Element with id 'error_success' will contain error and success alerts.
 * These alerts will be added as a paragraph element with text from servers.
 * Button with id 'send_email_button' will be disabled while server handles one request.
 */

'use strict';

/**
 * @description: Initializes the component. Subscribes global '/updater/add/confirmation' event
 */
function init() {
  $.subscribe('/updater/add/confirmation', add);
}

/**
 * Adds 'click' handler to '#send_email_button' button element
 */
function add() {
  $('#send_email_button').click(function () {
    setButtonDisabledStatus(true);
    $.post(
      `/${window.I18n.locale}/report/confirmation_email`,
      { email_address: $('#email_address').val() } // eslint-disable-line camelcase
    )
      .done((data) => addAlertBox('success', data.message))
      .fail((data) => addAlertBox('error', JSON.parse(data.responseText).errors))
      .always(() => setButtonDisabledStatus(false));
  });
}

/**
 * Adds alert box with passed message to '#error_success`element
 * @param {String} classSuffix Alert box class suffix. The final class will be 'email-<classSuffix>'
 * @param {String} message Message to be displayed
 */
function addAlertBox(classSuffix, message) {
  $('#error_success').empty();
  $(`<p id="email-${classSuffix}">${message}</p>`).appendTo('#error_success');
}

/**
 * Sets 'disabled' property of '#send_email_button' element to specified value
 * @param {Boolean} disabled Value to be set
 */
function setButtonDisabledStatus(disabled) {
  $('#send_email_button').prop('disabled', disabled);
}

module.exports = {
  init: init
};
