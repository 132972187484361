'use strict';

const modal = require('./modal');
const additionalDocAttachment = require("./additional-docs-attachment");

module.exports = (function(I18n){
  var closeSelectors = '#cboxClose, #delete_content, .m-modal-close';
  var resetSelectors = '#document-types';
  var submitCallbacksByType = {
    Upload: uploadModalHandlers,
    Email: emailModalHandlers,
    Post: postModalHandlers
  };

  function uploadModalHandlers() {
    $.subscribe("/modal/close", closePopup);

    $("form#document-dropzone").submit(function() {
      var form = this;

      if($(form).find("input:checked").length == 0 && $(form).data("types-present")) {
        addError(I18n.t("javascript.uploads.errors.document_type_required"));
        return false;
      }
      var errors = 0;
      var availableTypes = $(form).data("available-types");

      $.each(availableTypes, function(_index, type) {
        if($(form).find("#" + type)[0].files.length == 0 && $(form).find(".js-existing-" + type).length == 0) {
          errors += 1
        }
      });
      if(availableTypes.length == errors ) {
        addError(I18n.t("javascript.uploads.errors.uploaded_file_required.one_file_required"));
        return false;
      }
    });
  }

  function emailModalHandlers() {
    $.subscribe("/modal/close", closePopup);

    $("#email-form").submit(function(){
      var form = this;

      if($(form).find("input:checked").length == 0 && $(form).data("types-present")){
        addError(I18n.t("javascript.uploads.errors.document_type_required"));
        return false;
      }
    });
    $(document).on("click","#email-button",function(){
      var email = $("#email-text").val();
      href = $("#email-button").attr("href");
      $("#email-button").attr("href", href + "&body=" + email);
    })
  }

  function postModalHandlers() {
    $.subscribe("/modal/close", closePopup);

    $("#post-form").submit(function(){
      var form = this;

      if($(form).find("input:checked").length == 0 && $(form).data("types-present")){
        addError(I18n.t("javascript.uploads.errors.document_type_required"));
        return false;
      }
    })
  }

  function saveSummaryForm(callback) {
    const form = $('#report-form-wrapper > form');
    const action = form.attr('action');
    const formData = form.serialize();

    $.ajax({
      type: form.attr('method'),
      data: formData,
      url: action.substring(0, action.lastIndexOf('/')),
      success: function() {
        callback.call();
      }
    });
  }

  $(document).on('click','#upload',function(){
    saveSummaryForm(function(){
      submitCallbacksByType['Upload']();
    });
  });

  $('.additional-doc-edit-js').click(function() {
    const url = $(this).attr('href');
    const type = $(this).data('type');

    saveSummaryForm(function() {
      $.get(url).then(function (html) {
        modal.open({html});

        new additionalDocAttachment($('.file-upload-field'));
        subscribeToSubmit(type);
      });
    });

    return false;
  });

  $('.additional-doc-delete-js').click(function() {
    saveSummaryForm(function() {
      $.noop();
    });
    return true;
  });

  $(document).on('click','#email',function(){
    setTimeout( function(){
      submitCallbacksByType["Email"]();
    }, 1);
  });

  $(document).on('click','#post',function(){
    setTimeout( function(){
      submitCallbacksByType["Post"]();
    }, 1);
  });

  $(document).on("change", ".attachment-validate-js", (e) => {
    validateFiles(e.target)
  });

  $(document).on('click', closeSelectors, function(){
    $(".file-upload-field").each(function(idx, el){
      $(el).html($(el).html());
    });
    closePopup();
  });

  $(document).on('change', resetSelectors, function(){
    removeAllErrors();
  });

  function removeAllErrors(){
    $.publish('/updater/error/remove', [null, null, true]);
    modal.resize();
  }


  function closePopup(msg){
    if(msg){
      $.publish('/updater/error/remove', [msg]);
    }
    removeAllErrors();
    $.each($('.doc_type'),function(index, value) {
      value.checked = false;
    });
    $('.upload-des').val('');
  }

  function addError(msg){
    var context = $('.m-attachment');
    $.publish('/updater/error/add', [msg, null, context]);
    modal.resize();
  }

  function formatBytes(bytes, decimals) {
     if(bytes == 0) return '0 Bytes';
     var k = 1024,
         dm = decimals || 2,
         sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
         index = Math.floor(Math.log(bytes) / Math.log(k));
     return parseFloat((bytes / Math.pow(k, index)).toFixed(dm)) + ' ' + sizes[index];
  }

  function subscribeToSubmit(type) {
    submitCallbacksByType[type]();
  }

  function validateFiles(inputFile) {
    var allowedExtension = $(inputFile).data('allowed-extention');
    var extErrorMessage = I18n.t('javascript.uploads.errors.invalid_file_type', { file_types: allowedExtension });

    var extName;
    var fileSize;
    var maxFileSize = $(inputFile).data('max-file-size');
    var sizeExceeded = false;
    var extError = false;

    removeAllErrors();
    $.each(inputFile.files, function() {
      if (this.size && maxFileSize && this.size > maxFileSize) {
        sizeExceeded = true;
        fileSize = this.size;
      };
      extName = "." + this.name.split('.').pop();
      if ($.inArray(extName.toLowerCase(), allowedExtension) == -1) { extError=true; };
    });

    var maxExceededMessage = I18n.t('javascript.uploads.errors.file_too_big', { filesize: formatBytes(fileSize), maxFilesize: formatBytes(maxFileSize) });

    if (sizeExceeded) {
      addError(maxExceededMessage);
      $(inputFile).replaceWith($(inputFile).clone(true).val(''));
    };

    if (extError) {
      addError(extErrorMessage);
      $(inputFile).replaceWith($(inputFile).clone(true).val(''));
    };
  }

  return {
    validateFiles: validateFiles,
    subscribeToSubmit: subscribeToSubmit
  };

}(window.I18n));
