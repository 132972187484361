$(function () {

  var $elem = $('.m-additional-context');
  var $floatingContent = $elem.find('> fieldset')

  var breakpoint = $('.header-sticky').position().top; // needs to be set beforehand

  // When scroll is beyond the logo and the header becomes sticky
  function hasReachedBreakpoint() {
    // return $elem.position().top < $floatingContent.position().top
    return $(window).scrollTop() > breakpoint;
  }

  // When $elem's top and header's bottom are at the same level
  function hasReachedElemTop() {
    return $elem.offset().top === $floatingContent.offset().top
  }

  // When scroll is beyond the <main> element
  function hasReachedBottom() {
    return $elem.offset().top + $elem.height() <= $floatingContent.offset().top + $floatingContent.height()
  }

  /* Predicate function. Prerequisites are:
   * - Viewport width should be at least 1259px;
   * - <main>'s height should be more than $elem's height.
   */
  function prerequisitesAreMet() {
    return window.innerWidth >= 1259 &&
      $('main').height() > $floatingContent.height();
  }

  function maybeStickElem() {
    if (hasReachedBreakpoint()) {
      if ($floatingContent.css('position') !== 'fixed') { // came from the top
        $floatingContent.attr('class', 'fixed');
        $floatingContent.css({
          top: $('.header-sticky').outerHeight(),
          bottom: '',
          width: $elem.width()
        });
      } else {
        $floatingContent.css('width', $elem.width());
      }
    } else { // hasn't reached the breakpoint yet, or just went back to top
      if ($floatingContent.css('position') === 'fixed') { // was already beyond the breakpoint and just went back to the top
        $floatingContent.attr('class', 'static'); // set back to original
        $floatingContent.css('width', '');
      }
    }

    if (hasReachedElemTop()) {
      if ($floatingContent.css('position') === 'absolute') { // came from the bottom
        $floatingContent.attr('class', 'fixed');
        $floatingContent.css({
          top: $('.header-sticky').outerHeight(),
          bottom: '',
          width: $elem.width()
        });
      }
    }
    else if (hasReachedBottom()) {
      $floatingContent.attr('class', 'absolute');
      $floatingContent.css('top', '');
    }
  }

  function boot() {
    $floatingContent.css('width', $elem.width());
    $(window).on('scroll', function (event) {
      if (prerequisitesAreMet()) {
        maybeStickElem();
      } else {
        // just make sure the element is static (it might be that the prerequisites
        // were met before, but then the height changed because the user entered a lot of text)
        if ($floatingContent.css('position') !== 'static') {
          $floatingContent.attr('class', 'static'); // set back to original
          $floatingContent.css('width', '');
        }
      }

    });
    // Not related to the 'sticky' feature, but this fixes the public journey
    // because we don't have $elem in there.
    if ($elem.length === 0) {
      // $elem is not in the page, so <main> doesn't need to float and should be centered
      $('main').addClass('centered-block full-width');
    }
  }

  if ($elem.length) {
    boot();
  }
});
