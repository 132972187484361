/*!--------------------------------------------------------
 * tooltip
 * --------------------------------------------------------
 * @depends jquery.js
 * @depends jquery.pubsub.js
 */
'use strict';
module.exports = (function($, undefined) {

  // settings
  var s = {
    locale: 'en',
    selector: '.m-tooltip',
    el: undefined,
    offset: 5
  };

  /* --------------------------------------------------------
   * init
   * --------------------------------------------------------
  */
  function init(opts) {
    // recursively merge opts into settings
    $.extend(true, s, opts);
    s.locale = opts.locale || s.locale;

    $.subscribe('/updater/add', add);
    $.subscribe('/updater/add/tooltip', add);
  }

  /* --------------------------------------------------------
   * add
   * --------------------------------------------------------
   * adds tooltip functionality to context
  */
  function add(context) {
    $(context).find(s.selector).each(function() {
      var trigger = $(this),
          tooltip = trigger.data('tooltip'),
          hoverTrigger = trigger;

      if(!tooltip.length){
        return;
      }

      s.el = s.el || $('<div />', { 'id': s.selector.slice(1) }).appendTo('body');

      // we can't hover MM radios/checkboxes as
      // they are covered by custom buttons) so
      // attach event to parent
      if (hoverTrigger.is(':radio') ||
          hoverTrigger.is(':checkbox')) {
        hoverTrigger = trigger.parent();
        hoverTrigger.data('tooltip', tooltip);
      }

      trigger.on('focus', show).on('blur', hide);

      hoverTrigger.mouseenter( show ).mouseleave( hide );
    });
  }

  /* --------------------------------------------------------
   * hide
   * --------------------------------------------------------
   * hides the tooltip
  */
  function hide() {
    clearTimeout(s.timer);
    s.el.stop(true, false).fadeOut(100, function() {
      s.el.removeAttr('style');
    });
  }


  /* --------------------------------------------------------
   * show
   * --------------------------------------------------------
   * shows the tooltip in the correct position relative
   * to the trigger
  */
  function show(e) {
    var trigger = $(this),
        elWidth, elHeight;
    s.timer = setTimeout(function() {
      // add content to tooltip
      update(trigger.data('tooltip'));

      // need to get the outerWidth/Height
      // which doesn't work on hidden elements
      // so make jQuery think it's visible
      s.el.css({
        'visibility': 'hidden',
        'display': 'block'
      });

      elWidth = s.el.outerWidth();
      elHeight = s.el.outerHeight();

      // reset it's styling
      // back to how it was
      s.el.removeAttr('style');

      s.el.css({
        'left': (trigger.offset().left + (trigger.outerWidth()/2)) - (elWidth/2),
        'top': trigger.offset().top - elHeight - s.offset
      }).fadeIn(100);
    }, 300);
  }

  /* --------------------------------------------------------
   * update
   * --------------------------------------------------------
   * updates the contents of the tooltip
  */
  function update(content) {
    content = content.split('\n');

    // wrap paragraphs in <p> tags
    content = $.map(content, function(p, i) {
      return '<p>' + $.trim(p) + '</p>';
    }).join('');

    // don't manipulate the DOM
    // if it already contains
    // this content
    if (content !== s.currContent) {
      // add content to tooltip
      s.el.html(content);
      s.currContent = content;
    }
  }


  return {
    init: init
  };

}(jQuery));
