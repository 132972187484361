'use strict';

module.exports =  {
  conditionalMultiChoices: require('./conditionalMultiChoices'),
  updater: require('./updater'),
  tooltip: require('./tooltip'),
  externalSubmittion: require('./externalSubmittion'),
  zipcodes: require('./zipcodes'),
  converter: require('./converter'),
  yearOfBirth: require('./yearOfBirth'),
  options: require('./options'),
  field: require('./field'),
  autosize: require('./autosize'),
  assessmentToggler: require('./assessmentToggler'),
  sessionTimer: require('./sessionTimer'),
  autosuggest: require('./autosuggest'),
  modalSessionTimeout: require('./modal.sessionTimeout'),
  jumpTo: require('./jumpTo'),
  targetValidation: require('./targetValidation'),
  selectbox: require('./selectbox'),
  confirmation: require('./confirmation'),
  button: require('./button'),
  attachment: require('./attachment'),
  toggler: require('./toggler'),
  additional: require('./additional-docs-attachment'),
  additionalDocAttachment: require('./additional-docs-attachment'),
  deathToggler: require('./deathToggler'),
  modal: { ...require('./modal'), ...{welcome: require('./modal.welcome')}},
  normality: require('./normality'),
  reporterType: require('./reporterType')
};
