/*!--------------------------------------------------------
 * normaility
 * --------------------------------------------------------
 * @depends jquery.js
 * @depends jquery.pubsub.js
 */
module.exports = (function($, undefined) {
  'use strict';

  // settings
  var s = {
    locale: 'en',
    selector: '.m-normality',
    types: {
      normal: {
        selector: '.m-normality-normal',
        label: 'Normal'
      },
      abnormal: {
        selector: '.m-normality-abnormal',
        label: 'Abnormal'
      }
    }
  };

  /* --------------------------------------------------------
   * init
   * --------------------------------------------------------
  */
  function init(opts) {
    // recursively merge opts into settings
    $.extend(true, s, opts);
    s.locale = opts.locale || s.locale;

    $.subscribe('/updater/add', add);
    $.subscribe('/updater/add/normality', add);
  }

  /* --------------------------------------------------------
   * add
   * --------------------------------------------------------
   * adds normality functionality to context
  */
  function add(context) {
    $(context).findMe(s.selector).each(function() {
      var normality = $(this),

          type = $('<em />', {
            'class': s.selector.slice(1) + '-type ' + s.types.normal.selector.slice(1),
            text: s.types.normal.label
          }).insertAfter(normality),

          input = normality.find('input'),

          inst = {
            input: input,
            type: type
          };

      // get min/max values
      $.each(['min', 'max'], function(i, scale) {
        var value = input.data('normality-' + scale);
        inst[scale] = (value && isNaN(value*1)) ? input.nearest(value).val() : value;
        inst[scale] = tidyDecimal(inst[scale]);
      });

      input.on('keyup.normality change.normality', function() {
        var input = this;
        clearTimeout(s.timer);
        s.timer = setTimeout(function() {
          change.apply(input, [input, inst]);
        }, 300);
      }).data('m.normality', inst);

      if (input.val()) {
        change.apply(input[0], [input[0], inst]);
      }
    });
  }


  /* --------------------------------------------------------
   * update
   * --------------------------------------------------------
  */
  function update(context, data) {
    var context = $(context),
        values = {
          min: 0,
          max: 0,
          unit: ''
        },
        match = false;

    $.each(data, function(name, value) {
      var field = context.find('[name="' + name + '"]');

      if (field.length) {
        match = true;

        $.each(values, function(type, val) {
          if (field.hasClass(s.selector.slice(1) + '-' + type)) {
            var value = field.val();

            if (type === 'min' || type === 'max') {
              value = tidyDecimal(value);
            }

            values[type] = value;
          }
        });
      }
    });

    if (match) {
      context.find(s.selector).each(function() {
        var normality = $(this),
            input = normality.find('input'),
            inst = input.data('m.normality');

        if (inst) {
          inst.min = values.min;
          inst.max = values.max;
          normality.find(s.selector + '-unit').text(values.unit);
          change.apply(input[0], [input[0], inst]);
        }
      });
    }
  }

  /* --------------------------------------------------------
   * change
   * --------------------------------------------------------
  */
  function change(input, inst) {
    var result = 'abnormal',
        value = input.value;

    // if only entered a '.'
    // then we don't want to
    // validate normality yet
    if (value === '.') {
      return;
    }

    // make normality visible if we have a value
    inst.type.css('visibility', ((value || value === 0) ? 'visible' : 'hidden'));

    value = tidyDecimal(value);

    // check if value is between range or equal to min/max
    if ((value > inst.min && value < inst.max) ||
        (value == inst.min || value == inst.max)) {
      result = 'normal';
    }

    if (!inst.type.hasClass(s.types[result].selector.slice(1))) {
      inst.type
        .removeClass(s.types.abnormal.selector.slice(1) + ' ' + s.types.normal.selector.slice(1))
        .addClass(s.types[result].selector.slice(1))
        .text(s.types[result].label);
    }
  }

  /* --------------------------------------------------------
   * tidyDecimal
   * --------------------------------------------------------
   * min and max should always follow [0-9]+(\.[0-9]+)?
   * format. so this will change '.5' into '0.5' for example
  */
  function tidyDecimal(value) {
    value = (('' + value).indexOf('.') === 0) ? 0 + value : value;
    // convert to number
    return value*1;
  }

  return {
    init: function () {},
    selector: s.selector,
    update: update
  };

}(jQuery));
