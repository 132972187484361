/*!--------------------------------------------------------
 * field
 * --------------------------------------------------------
 * @depends jquery.js
 * @depends jquery.pubsub.js
 */
module.exports = (function($, undefined) {
  'use strict';

  // settings
  var s = {
    locale: 'en',
    selector: '.m-field'
  };

  /* --------------------------------------------------------
   * init
   * --------------------------------------------------------
  */
  function init(opts) {
    // recursively merge opts into settings
    $.extend(true, s, opts);
    s.locale = opts.locale || s.locale;

    $.subscribe('/updater/add', add);

    // specific subscription for when module
    // hasn't been initialised but is necessary
    // within generic /add's context. generic
    // add inits module and then publishes
    // specific subscription
    $.subscribe('/updater/add/field', add);
  }

  /* --------------------------------------------------------
   * add
   * --------------------------------------------------------
   * adds field functionality to context
  */
  function add(context) {
    $(context).on('focusin.field focusout.field', ':input', toggle);
  }

  /* --------------------------------------------------------
   * create
   * --------------------------------------------------------
   * converts fields (within context) into field modules
   * useful for markup generated by plugins
  */
  function create(context) {
    var cssClass = s.selector.slice(1),
        update = false;

    $.each(context.find(':input'), function(i, f) {
      var field = $(f),
          fieldParent = field.parent();

      if (fieldParent.hasClass(cssClass)) {
        // do nothing if it's already
        // a field module
        return true;
      }

      field.wrap('<span class="' + cssClass + '" />');
      update = true;
    });

    if (update) {
      // update ui to bind field module functionality
      $.publish('/updater/add/field', [context]);
    }
  }

  /* --------------------------------------------------------
   * toggle
   * --------------------------------------------------------
   * toggle the active class on the field module
  */
  function toggle(e, silent) {
    var focus = (e.type === 'focusin'),
        action = focus ? 'addClass' : 'removeClass',
        field = $(e.target).closest(s.selector),
        active = s.selector.slice(1) + '-active';

    field[action](active);
    $.publish('/field/toggle', [field]);

    e.stopPropagation();
  }

  /* --------------------------------------------------------
   * focus
   * --------------------------------------------------------
   * toggle alias
  */
  function focus(e) {
    toggle(e);
  }

  /* --------------------------------------------------------
   * blur
   * --------------------------------------------------------
   * toggle alias
  */
  function blur(e) {
    toggle(e);
  }

  return {
    selector: s.selector,
    init: init,
    focus: focus,
    blur: blur,
    create: create
  };

}(jQuery));
