/*!--------------------------------------------------------
 * welcome modal
 * --------------------------------------------------------
 * @depends jquery.js
 * @depends jquery.pubsub.js
 */
module.exports = (function($, I18n, undefined) {
  'use strict';

  // settings
  var s = {
    locale: 'en',
    selector: '#m-welcome-modal',
    errorFields: [],
    validFields: [],
    element: undefined,
    required: undefined,
    valid: true
  };

  /* --------------------------------------------------------
   * init
   * --------------------------------------------------------
  */
  function init(modal, opts) {
    // recursively merge opts into settings
    $.extend(true, s, opts);
    s.locale = opts.locale || s.locale;
    s.element = modal;

    s.element
      .attr('tabindex', 0)
      .trigger('focus')
      .removeAttr('tabindex')
      .on('click', '.m-buttons a', cancel)
      .on('click', '.m-buttons button', accept);

    s.required = s.element.find('.required');
    s.closeButton.hide();
  }

  /* --------------------------------------------------------
   * accept
   * --------------------------------------------------------
  */
  function accept() {
    var field, valid;

    s.errorFields = [];
    s.validFields = [];

    // validate the required fields
    s.required.each(function() {
      field = $(this);
      valid = ((field.is(':radio') || field.is(':checkbox')) ? field.is(':checked') : field.val());
      s[valid ? 'validFields' : 'errorFields'].push(field.add(field.next()));
    });

    $.each(s.validFields, function() {
      $.publish('/updater/error/remove', [this]);
    });

    $.each(s.errorFields, function() {
      $.publish('/updater/error/add', [I18n.t('javascript.terms_error'), this, s.element]);
    });

    if (!s.errorFields.length) {
      $.post(field.data('url'));
      $.publish('/updater/error/remove', [I18n.t('javascript.terms_error')]);
      $.colorbox.close();
    }

    $.publish('/modal/update');
  }

  /* --------------------------------------------------------
   * cancel
   * --------------------------------------------------------
  */
  function cancel(e) {
    window.history.go(-1);
    e.preventDefault();
  }

  return {
    selector: s.selector,
    init: init
  };

}(jQuery, window.I18n));
